import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENV_CONFIG, EnvironmentConfig } from 'src/app/core/enviroment-config';
import { DictionaryApiService } from 'src/app/core/service/abstract/dictionary-api.service';
import { Permission } from 'src/app/data/model/permission';

@Injectable({
  providedIn: 'root',
})
export class PermissionApiService extends DictionaryApiService<Permission> {
  constructor(http: HttpClient, @Inject(ENV_CONFIG) config: EnvironmentConfig) {
    super('portal/auth/permissions', http, config);
  }
}
