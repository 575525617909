import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, tap } from 'rxjs';
import { PermissionApiService } from 'src/app/shared/api/services/permission-api.service';
import { AuthService } from 'src/app/core/service/auth.service';
import { TokenService } from 'src/app/core/service/token.service';
import { AppActions } from '.';

/**
 * Effetti che agiscono sull'intera app
 */
@Injectable()
export class AppEffects {
  constructor(
    private actions$: Actions,
    private tokenService: TokenService,
    private authService: AuthService,
    private permissionService: PermissionApiService,
    private router: Router
  ) {}

  $setToken = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.setToken),
      tap((action) => this.tokenService.setToken(action.token)),
      mergeMap(() => [AppActions.loadUser(), AppActions.loadPermission()])
    )
  );

  $getUser = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.loadUser),
      mergeMap(() =>
        this.authService.getUser().pipe(
          map((response) => AppActions.loadUserSuccess({ user: response })),
          catchError((response) =>
            of(AppActions.loadUserFailure({ response: response }))
          )
        )
      )
    )
  );

  $getPermission = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.loadPermission),
      mergeMap(() =>
        this.permissionService.getAll().pipe(
          map((response) =>
            AppActions.loadPermissionSuccess({ permissions: response })
          ),
          catchError((response) =>
            of(AppActions.loadPermissionFailure({ response: response }))
          )
        )
      )
    )
  );

  $getUserFailure = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppActions.loadUserFailure, AppActions.loadPermissionFailure),
        tap(() => {
          //TODO show error message
          this.tokenService.clearToken();
        })
      ),
    { dispatch: false }
  );

  $logOut = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.logOut),
      mergeMap(() =>
        this.authService.logOut().pipe(
          map(() => AppActions.logOutSuccess()),
          catchError((err) => of(AppActions.logOutFailure({ response: err })))
        )
      )
    )
  );

  $logOutSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppActions.logOutSuccess),
        tap(() => {
          this.tokenService.clearToken();
          this.router.navigate(['/login']);
        })
      ),
    {
      dispatch: false,
    }
  );

  $logOutFailure = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppActions.logOutFailure),
        tap((err) => {
          console.log(err);
          //TODO show error message
        })
      ),
    {
      dispatch: false,
    }
  );
}
