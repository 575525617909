import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TokenService } from '../service/token.service';

@Injectable()
export class SecurityInterceptor implements HttpInterceptor {
  public constructor(private tokenService: TokenService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (!this.tokenService.isAuthenticated()) {
      return next.handle(request);
    }
    const reqAuth = request.clone({
      headers: request.headers.set(
        'Authorization',
        `Token ${this.tokenService.getToken()}`
      ),
    });

    return next.handle(reqAuth);
  }
}
