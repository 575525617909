import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { finalize, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  loadingDecrease,
  loadingIncrease,
} from 'src/app/store/app-store/app-store.actions';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(private store: Store) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    this.store.dispatch(loadingIncrease());
    return next
      .handle(request)
      .pipe(finalize(() => this.store.dispatch(loadingDecrease())));
  }
}
