import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { EnvironmentConfig, ENV_CONFIG } from './enviroment-config';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SecurityInterceptor } from './interceptor/security.interceptor';
import { TokenService } from './service/token.service';
import { Store } from '@ngrx/store';
import { AppActions } from '../store/app-store';
import { LoadingInterceptor } from './interceptor/loading.interceptor';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [],
  imports: [HttpClientModule, SharedModule],
  providers: [
    TokenService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [TokenService, Store],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SecurityInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule {
  static forRoot(config: EnvironmentConfig): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: ENV_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}

function initializeApp(tokenService: TokenService, store: Store) {
  return () => {
    if (tokenService.isAuthenticated()) {
      store.dispatch(AppActions.loadUser());
      store.dispatch(AppActions.loadPermission());
    }
  };
}
