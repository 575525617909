import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { AppSelectors } from 'src/app/store/app-store';

@Injectable({
  providedIn: 'root',
})
export class IsAuthenticatedGuard implements CanLoad, CanActivate {
  constructor(private store: Store, private router: Router) {}

  canActivate():
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this.store.select(AppSelectors.selectUser).pipe(
      map((user) => {
        console.log('current url: ', this.router.url, ", result: ", user ? true : this.router.parseUrl('/login'));
        return user ? true : this.router.parseUrl('/login');
      })
    );
  }

  canLoad(): Observable<boolean> {
    return this.store
      .select(AppSelectors.selectUser)
      .pipe(map((user) => !!user));
  }
}
