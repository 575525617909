import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServiceOrder } from 'src/app/data/model/service-order';
import { SendOrderApiService } from '../../api/services/send-service-order-api.service';

@Component({
  templateUrl: './send-orders.component.html',
  styles: [],
})
export class SendOrdersComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: ServiceOrder,
    private sendservice: SendOrderApiService) { }

  get params(): any {
    return this.data;
  }
  SendOrder(data: ServiceOrder): void {
    this.sendservice.send(data.assignedVehicle, data.start_date).subscribe();
  }
}

export type SendOrdersParams = {
  data: any;
};
