export const environment = {
  production: true,
  apiUrl: 'https://api.ambienteservizi.net/api/v1',
};

export const service_order = {
  production: true,
  defaultGridColumns: ['ods_id', 'extradata', 'start_date', 'end_date', 'ts_ods_start', 'ts_ods_end', 'assignedDriver', 'assignedLicensePlate', 'status', 'duration'],
  gridColumns: '${gridColumns}'
};
