import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './app-store.effects';
import { appReducer } from './app-store.reducer';
import { FEATURE_APP } from './app-store.selectors';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(FEATURE_APP, appReducer),
    EffectsModule.forFeature([AppEffects]),
  ],
})
export class AppStoreModule {}
