import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Actions } from 'src/app/data/model/permission';

import { State } from './app-store.state';

export const FEATURE_APP = 'app';

export const selectAppState = createFeatureSelector<State>(FEATURE_APP);

export const selectUser = createSelector(selectAppState, (state) => state.user);
export const selectLoading = createSelector(
  selectAppState,
  (state) => state.loadingCount > 0
);

export const selectNavbar = createSelector(
  selectAppState,
  (state) => state.navbar
);

export const selectPermissions = createSelector(
  selectAppState,
  (state) => state.permissions
);

export const selectViewPermssions = createSelector(selectAppState, (state) =>
  state.permissions.filter((item) => item.action === Actions.VIEW)
);
