import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-form-api-validation-errors',
  templateUrl: './form-api-validation-errors.component.html',
  styleUrls: ['./form-api-validation-errors.component.scss'],
})
export class FormApiValidationErrorsComponent {
  @Input() errors: Map<string, string[]>;
}
