import { Directive, OnDestroy } from '@angular/core';
import { MonoTypeOperatorFunction, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * Classe astratta che tutti i component estendono per la gestione
 * del'unsubscribe tramite il suo metodo protected autoDispose
 *
 * @export
 * @abstract
 * @class BaseComponent
 * @implements {OnDestroy}
 */
@Directive()
export abstract class BaseComponent implements OnDestroy {
  private unsubscribeSubject: Subject<void> = new Subject();

  ngOnDestroy() {
    this.onDestroy();
  }

  /**
   * Esegue le operazioni di pulizia del componente, tramite l'unsubscribe
   * di tutti i subject registrati
   *
   * @protected
   * @memberof BaseComponent
   */
  protected onDestroy() {
    this.unsubscribeSubject.next();
    this.unsubscribeSubject.complete();
  }

  /**
   * Esegue alla distruzione del componente l'unsubscribe di tutti
   * i subject in esso registrati.
   *
   * @protected
   * @template T
   * @return {*}  {MonoTypeOperatorFunction<T>}
   * @memberof BaseComponent
   */
  protected autoDispose<T>(): MonoTypeOperatorFunction<T> {
    return takeUntil(this.unsubscribeSubject);
  }
}
