<h2 mat-subheader>{{ item?.label }}</h2>

<mat-list-item
  *ngFor="let child of item?.items"
  routerLinkActive="active"
  [routerLink]="child.link"
  [routerLinkActiveOptions]="{ exact: true }"
  (click)="hideMenu()"
>
  <a matLine class="menu-link">{{ child.label }}</a>
</mat-list-item>
