import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Permission } from 'src/app/data/model/permission';
import { User } from 'src/app/data/model/user';
import { AppActions, AppSelectors } from 'src/app/store/app-store';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  public user$: Observable<User> = this.store.select(AppSelectors.selectUser);
  public viewPermission$: Observable<Permission[]> = this.store.select(
    AppSelectors.selectViewPermssions
  );

  constructor(private store: Store) {}

  public toggleMenu() {
    this.store.dispatch(AppActions.toggleNav());
  }
}
