import { Inject, Injectable } from '@angular/core';
import {
  SESSION_STORAGE,
  StorageService,
  StorageTranscoders,
} from 'ngx-webstorage-service';
import { Constant } from 'src/app/data/constant';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  constructor(@Inject(SESSION_STORAGE) private storage: StorageService) {}

  isAuthenticated(): boolean {
    return this.storage.has(Constant.TOKEN_KEY);
  }

  getToken(): string {
    return this.storage.get(Constant.TOKEN_KEY);
  }

  setToken(token: string) {
    this.storage.remove(Constant.TOKEN_KEY);
    this.storage.set(Constant.TOKEN_KEY, token, StorageTranscoders.JSON);
  }

  clearToken() {
    this.storage.remove(Constant.TOKEN_KEY);
  }
}
