import {
  ChangeDetectionStrategy,
  Component,
  Input,
} from '@angular/core';
import { Menu } from '../../navigation-menu.component';

@Component({
  selector: 'app-navigation-menu-item',
  templateUrl: './navigation-menu-item.component.html',
  styleUrls: ['./navigation-menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationMenuItemComponent {
  @Input() item: Menu;
}
