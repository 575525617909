import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { User } from 'src/app/data/model/user';
import { AppActions, AppSelectors } from 'src/app/store/app-store';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserMenuComponent implements OnInit {
  public user$: Observable<User | null> | undefined;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.user$ = this.store.select(AppSelectors.selectUser);
  }

  getUserName(user: User) {
    return user.first_name && user.last_name
      ? `${user.first_name} ${user.last_name}`
      : user.username;
  }

  logOut() {
    this.store.dispatch(AppActions.logOut());
  }
}
