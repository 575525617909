<div>
  <h2 mat-dialog-title></h2>
  <mat-dialog-content >
    <p *ngIf="data">Sei sicuro di voler inviare gli ordini ai mezzi ?</p>
    <p *ngIf="!data">Nessun dato da inviare</p>
  </mat-dialog-content>
  <mat-dialog-actions align="bottom">
    <button mat-button mat-dialog-close>Annulla</button>
    <button
      mat-button
      class=""
      (click)="SendOrder(data)"
      [mat-dialog-close]="true"
      cdkFocusInitial
      color="warning"
    >
      Invia
    </button>
  </mat-dialog-actions>
</div>