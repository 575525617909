import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SafeUrl } from '@angular/platform-browser';
import { saveAs } from 'file-saver';
//import { QRDialogService } from './services/QRcode-dialog.service';

@Component({
  templateUrl: './QRcode-dialog.compnent.html',
  styles: [],
  //providers:[QRDialogService]
})
export class QRDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public qrCodeImageUrl:[SafeUrl, Blob]){}

  async downloadQRcode(){
    saveAs(this.qrCodeImageUrl[1], "vehicle_code.png");
   }
  
}