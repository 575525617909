import { Pipe, PipeTransform } from '@angular/core';
/**
 * Estrae da un testo le prime due iniziali mettendole a upper case
 *
 * @param value la stringa da convertire
 */
@Pipe({
  name: 'initialsUpper',
})
export class InitialsUpperPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return value;
    }
    if (typeof value !== 'string') {
      throw TypeError('parametro non valido!');
    }
    const text = value.toUpperCase().trim();
    const a: string[] = text.split(' ', 2);
    let temp = '';
    a.forEach((element) => {
      temp += element.charAt(0);
    });
    return temp;
  }
}
