import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { User } from 'src/app/data/model/user';
import { EnvironmentConfig, ENV_CONFIG } from '../enviroment-config';
import { BaseService } from './abstract/base.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService {
  constructor(
    protected http: HttpClient,
    @Inject(ENV_CONFIG) config: EnvironmentConfig
  ) {
    super(config);
  }

  login(data: { username: string; password: string }) {
    const path = this.getPath('rest-auth/login/');

    return this.http.post<{ key: string }>(path, data, {
      headers: this.createHeaders(),
    });
  }

  getUser() {
    const path = this.getPath('rest-auth/user/');

    return this.http.get<User>(path, {
      headers: this.createHeaders(),
    });
  }

  logOut() {
    const path = this.getPath('rest-auth/logout/');

    return this.http.post(path, {
      headers: this.createHeaders(),
    });
  }
}
