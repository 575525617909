<div *ngIf="user$ | async as user; else login">
  <button
    mat-button
    color="default"
    aria-label="Menu con profilo utente e Esci"
    style="color: white"
    [matMenuTriggerFor]="menu"
  >
    <button mat-mini-fab color="default">
      <span class="user-image">{{ getUserName(user) | initialsUpper }}</span>
    </button>

    <span fxHide.lt-md>&nbsp; {{ getUserName(user) | titlecase }}</span>
  </button>

  <mat-menu #menu="matMenu">
    <button
      mat-menu-item
      (click)="logOut()"
      matTooltip="Effettua il logout dall'applicazione"
    >
      <mat-icon>logout</mat-icon>&nbsp;Logout
    </button>
  </mat-menu>
</div>

<ng-template #login>
  <button mat-button [routerLink]="['/login']">Login</button>
</ng-template>
