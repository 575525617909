import { HttpHeaders, HttpParams } from '@angular/common/http';
import { EnvironmentConfig } from '../../enviroment-config';
/**
 * Classe astratta di servizio per effettuare le chiamate al backend applicativo
 */
export abstract class BaseService {
  /**
   * Inietta la configurazione con url per il backend
   * @param config
   */
  constructor(private config: EnvironmentConfig) {}

  /**
   * Restituisce il path completo alla Api, tale path viene completato poi
   * nelle singole chiamate per produrre l'url al metodo da invocare:
   * @param path il path per completare
   */
  protected getPath(path: string): string {
    return this.config.environment.apiUrl
      ? `${this.config.environment.apiUrl}/${path}`
      : `/${path}`;
  }

  /**
   * Crea una nuova instanza di HttpHeaders
   * @returns header
   */
  protected createHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', '*/*');
    return headers;
  }

  protected convertToHttpParams(req?: any): HttpParams {
    let params: HttpParams = new HttpParams();

    if (req) {
      for (const key in req) {
        if (typeof req[key] === 'undefined' || req[key] === null) continue;
        if (Array.isArray(req[key]) && req[key].length === 0) continue;

        params = params.set(key, req[key]);
      }
    }

    return params;
  }
}
