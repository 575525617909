import { HttpClient } from '@angular/common/http';
import { Observable, of, tap } from 'rxjs';
import { EnvironmentConfig } from '../../enviroment-config';
import { BaseService } from './base.service';
/**
 * Classe astratta di servizio per effettuare le chiamate al backend applicativo
 */
export abstract class DictionaryApiService<T> extends BaseService {
  constructor(
    private basePath: string,
    protected http: HttpClient,
    config: EnvironmentConfig
  ) {
    super(config);
  }

  private cachedResponse: T[];

  getAll(): Observable<T[]> {
    if (this.cachedResponse) {
      of([...this.cachedResponse]);
    }

    const path = this.getPath(`${this.basePath}`);
    return this.http
      .get<T[]>(path)
      .pipe(tap((data) => (this.cachedResponse = [...data])));
  }
}
