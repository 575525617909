import { User } from 'src/app/data/model/user';
import { Permission } from 'src/app/data/model/permission';

export interface State {
  user: User | null;
  loadingCount: number;
  navbar: boolean;
  permissions: Permission[];
}

export const initialState: State = {
  user: null,
  loadingCount: 0,
  navbar: false,
  permissions: [],
};
