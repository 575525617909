<h2 mat-dialog-title>Attenzione</h2>
<mat-dialog-content class="mat-typography">
  <p *ngIf="message">{{ message }}</p>
  <p *ngIf="!message">Le modifiche non salvate andranno perse</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Annulla</button>
  <button
    mat-button
    class=""
    [mat-dialog-close]="true"
    cdkFocusInitial
    color="warning"
  >
    Continua
  </button>
</mat-dialog-actions>
