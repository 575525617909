<div fxHide.lt-md class="flex">
  <div *ngFor="let item of currentMenu" fxFlex >
    <app-navigation-menu-item [item]="item"></app-navigation-menu-item>
  </div>
</div>

<div fxHide.gt-sm>
  <mat-nav-list>
    <ng-container *ngFor="let item of currentMenu">
      <app-navigation-list-item [item]="item"></app-navigation-list-item>
    </ng-container>
  </mat-nav-list>
</div>
