import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { AppSelectors } from 'src/app/store/app-store';

@Injectable({
  providedIn: 'root',
})
export class IsAnonymousGuard implements CanLoad, CanActivate {
  constructor(private store: Store, private router: Router) {}
  canActivate():
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this.store
      .select(AppSelectors.selectUser)
      .pipe(map((user) => (user ? this.router.parseUrl('/login') : true)));
  }

  canLoad(): Observable<boolean> {
    return this.store
      .select(AppSelectors.selectUser)
      .pipe(map((user) => !user));
  }
}
