<button mat-button [matMenuTriggerFor]="menuPanel">
  {{ item?.label }}
</button>
<mat-menu #menuPanel>
  <button
    *ngFor="let child of item?.items"
    mat-menu-item
    [routerLink]="child.link"
    routerLinkActive="active"
  >
    {{ child.label }}
  </button>
</mat-menu>
