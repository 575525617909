import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SelectLanguageComponent } from './select-language/select-language.component';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [TranslateModule, SelectLanguageComponent],
  declarations: [SelectLanguageComponent],
})
export class I18nModule {
  constructor(translate: TranslateService) {
    translate.addLangs(['it', 'en']);
    // const browserLang = translate.getBrowserLang();
    // translate.use(browserLang.match(/it|en/) ? browserLang : 'it');
    translate.use('it');
  }
}

export function translateLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}
