import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-select-language',
  templateUrl: './select-language.component.html',
  styleUrls: ['./select-language.component.scss'],
})
export class SelectLanguageComponent {
  constructor(public translate: TranslateService) {}

  selectLang(lang: string) {
    this.translate.use(lang);
  }
}
